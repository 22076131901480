import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import {
  AuthUserContext,
  withAuthorization,
} from '../components/Session';
import PasswordChangeForm from '../components/PasswordChange';
import Navigation from '../components/Navigation';

const AccountPageBase = () => (
  <div>
    <Navigation />

    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Account
        </h1>
      </div>
    </header>

    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className='sm:w-full w-11/12 mx-auto'>
          <div className='sm:w-full w-1/2 mt-12'>
            <AuthUserContext.Consumer>
              {authUser => (
                <div>
                  <h1 className='text-lg mb-8'><b>Your email:</b> {authUser.email}</h1>

                  <h2 className='text-lg mb-2 font-bold mt-8'>Change Password</h2>
                  <PasswordChangeForm />

                </div>
              )}
            </AuthUserContext.Consumer>
          </div>
        </div>
      </div>
    </main>
  </div>
);

const condition = authUser => !!authUser;

const AccountPage = compose(
  withAuthorization(condition),
)(AccountPageBase);

export default () => (
  <Layout>
    <AccountPage />
  </Layout>
);
